/****************************
Table of Contents

	Positioning Styles
	Media Arrow_Nav and Slider modifications
	Other
 ****************************/

/****************************
Positioning styles	
 ****************************/
.ht-1509{
	height: 1509px !important;
}
.w-3300{
	width: 3300px !important;
}
.mg-30-bottom {
	margin-bottom: 30px;
}

.mg-30-tb {
	margin: 30px 0;
}
.m-10{
	margin: 10px !important;
}
.center-text{
	text-align:center !important;
}
.top-0{
	top: 0px !important;
}
.top-10{
	top: 10px !important;
}
/****************************
Media Arrow_Nav and Slider modifications
 ****************************/
.arrow_dark{
	background-color: black !important;
}

@media(min-width:1129px){
	.video-gallery .ctrl_nav{
		transform: none !important;
		left: 0px !important;
		position: relative !important;
		margin-top: 20px !important;
		margin-bottom: 18px !important;
		padding-top: 15px !important;
	}
	.arrow_nav{
		top: 18px !important;
	}
}
/****************************
Other
 ****************************/
.img-repsonsive.full-width {
	width: 100%;
	max-width: 100%;
	height: auto;
}

@media (max-width: 992px) {
	.mg-30-tb {
		margin: 30px auto;
	}
}

h1.primary {
	font-size: 46px;
}

@media (max-width: 1200px) {
	h1.primary {
		font-size: 32px;
	}
}

h3 {
    font-size: 28px;
    color: #145982;
    margin-bottom: 10px;
}

p {
	font-size: 18px;
}

.thin {
	font-weight: 300;
}

.extra-thin {
	font-weight: 100;
}

.green {
	color: #3ab248;
}

h2.slogan {
	font-weight: 700;
	color: white;
	text-transform: none;
	font-style: italic;
	font-size: 42px;
	padding: 20px 0;
}

.bg-blue {
	background: #014166;
}

ul.primary {
	margin-bottom: 30px;
	padding-left: 15px;
	line-height: 2;
}

ul.primary ul.inner {
	padding-left: 30px;
}

@media (max-width: 480px) {
	.mega-list ul.primary ul.inner {
		padding-left: 25px;
	}
}

.type-none {
	list-style-type:none; 
}

@media (max-width: 480px) {
	.mega-list ul.primary {
		padding-left: 5px;
	}
}


.pd-primary {
	padding: 60px 0;
}

.pd-secondary {
	padding: 30px 0;
}

.pd-10 {
	padding: 10px 0;
}

.upper {
	text-transform: uppercase;
}

.primary-inline {
	display: inline-block;
	padding-left: 0;
	margin: 0 auto;
	text-align: center;
	width: 100%;
}

.primary-inline li {
	display: inline;
	margin: 0 15px;
}

.primary-inline li a {
	display: inline-block;
	color: white;
	font-size: 22px;
	position: relative;
	font-weight: 700;
}

@media (max-width: 992px) {
	.primary-inline li a {
		font-size: 18px;
	}
}

.primary-inline li a.no-mark:before {
	content: '';
}

.primary-inline li a:hover,
.primary-inline li a:focus {
	text-decoration: underline;
}

.primary-inline li a:before {
    content: '.';
    font-size: 100px;
    height: 3px;
    line-height: 0;
    position: absolute;
    left: -27px;
    top: -11px;
    font-weight: 300;
}

@media (max-width: 992px) {
	.primary-inline li a:before {
		top: -15px;
		left: -28px;
	}
}

@media (max-width: 769px) {
	.primary-inline li a:before {
		content: '';
	}
	.primary-inline li a {
		display: block;
	}
}

li span {
	font-weight: 700;
}

.logo-block-wrap {
	max-width: 769px;
	width: 100%;
	margin: 0 auto;
	display: block;
}

.logo-block {
	background: rgba(255, 255, 255, .4);
	border: 1px solid #145982;
	padding: 20px;
}

.logo-block img {
	max-width: 95%;
	width: 100%;
	margin: 0 auto 30px auto;
	display: block;
}

.logo-block p.name {
	font-size: 28px;
	font-weight: 700;
}

.logo-block p.site {
	margin-bottom: 20px;
}

.logo-block p {
	line-height: 1.2;
	margin: 0;
	margin-bottom: 10px;
	text-align: center;
}

.connection-wrap .qr {
	max-width: 100px;
}

@media (max-width: 992px) {
	.connection-wrap .qr {
		max-width: 100px;
		margin: 50px auto 0 auto;
		display: block;
	}
}

.sting-text {
	font-size: 29px;
	line-height: 1.5;
	font-weight: 500;
	max-width: 1100px;
	margin: 0 auto;
	display: block;
}

.sting-text.lrg {
	font-size: 38px;
}

img.img-repsonsive.pull-right {
	max-width: 400px;
	width: 100%;
	float: right;
}

@media (max-width: 992px) {
	img.img-repsonsive.pull-right {
		width: 100%;
		max-width: 100%;
		float: none;
	}
}

@media (max-width: 769px) {
	.acronym {
		margin-bottom: 5px;
	}
}

/****************************
Cookies
 ****************************/

 #consentForm p a {
    color: #999 !important;
 }
